<template>
    <div class="flex justify-center items-center cursor-pointer h-10 w-10" @click="handler">
      <img
          src="@/assets/images/icons/vertical-single-bar.svg"
          :class="{'transform rotate-90' : !isExpanded}"
      />
    </div>
</template>

<script>
export default {
    name: 'CollapseHandle',
    props: {
        handler: Function,
        isExpanded: {
            default: true
        }
    }
}
</script>
