import { getParameterTitles } from '@/store/modules/roadmap/services.js'
import { getParameterBg } from '@/store/services/traits/parameter.js'

import store from '@/store'
import layout from "@/components/brainstormv2/concept/Layout";
export default class Association {
    constructor({ filteredCollections, isolatedModules, module, layout }) {
        this.filterCollections = filteredCollections;
        this.associations = [];
        this.minRange = [0, 2, 4, 6, 8];
        this.maxItemLength = 1;
        this.selectedModuleId = '';
        this.isolatedModules = isolatedModules;
        this.module = module;
        this.layout = layout;
    }

    set associationsArr(associations) {
        this.associations = associations;
    }

    setAssociatedItemsMaxLength(associatedModules) {
        const length = associatedModules.length
        if (length > this.maxItemLength) {
            this.maxItemLength = length
        }
    }

    get associationsArr() {
        return this.associations;
    }

    set moduleId(id) {
        this.selectedModuleId = id
    }

    get moduleId() {
        return this.selectedModuleId
    }

    getAssociations(ids) {
        store.dispatch('roadmap/fetchAssociationByCollection', ids)
            .then(res => {
                this.associations = res.data.data
                this.getFilterCollectionWithAssociatedModules()
            })
    }

    removeAssociation(scenarioCollectionId, associatedId) {
        const association = this.associations.find(item => item.scenario_collection_id = scenarioCollectionId && item.associated_id == associatedId)
        const index = this.associations.indexOf(association)
        this.associations.splice(index, 1)
    }

    addRemoveAssociation(data, ids, type = 'add') {
        return store.dispatch("programmatic/addOrRemovesAssociation", data)
            .then(res => {
                this.getAssociations(ids)
                return res;
            });
    }

    /*
        *Returns array of association
        *@param {number} id The id of filterCollection
    */
    getAssociationsByFilterCollectionId(id) {
        if (this.associations?.length) {
            return this.associations.filter(association => association.scenario_collection_id == id)
        }
        return []
    }

    /*
        *Returns a Array of module
        *@param {object} data roadmap data
        *@param {number} id The selected module Id
    */
    getModules(data) {
        if (data.modules) {
            return data.modules
        }
        return []
    }

    /*
        *Returns a Module Object
        *@param {object} data raodmap modules
        *@param {number} id The selected module Id
    */
    getModule(data, id) {
        return data.find(item => item.id == id)
    }

    /*
        *Returns a associated modules array
        *@param {object} data roadmap data
        *@param {number} id The id of filterCollection
        *@param {number} moduleId The id of selected module
    */
    // getAssociatedModules(data, id, moduleId) {
    //     const modules = this.getModules(data)
    //     const module = this.getModule(modules, moduleId)
    //     const associations = this.getAssociationsByFilterCollectionId(id)
    //     if(!module || !associations.length) {
    //         return []
    //     }
    //     const associatedIds = associations.map(item => item.associated_id)
    //     const moduleLastLevels = module.last_levels
    //     let associatedModules =  moduleLastLevels.filter(item => {
    //         if(associatedIds.includes(item.id)) {
    //             return item
    //         }
    //     })
    //     this.setAssociatedItemsMaxLength(associatedModules)
    //     return associatedModules
    // }

    getData(items) {
        if(!this.isolatedModules) {
            return items;
        }
        let moduleInIsolation = this.isolatedModules.find(item => item && item.module_id === this.module.id);
        if (moduleInIsolation && moduleInIsolation.isolate) {
            return items.filter(data => moduleInIsolation.isolatedIds.includes(data.id) && !data.root);
        } else if(this.layout.isolated_ids && this.layout.isolated_ids.length > 0) {
            return items.filter(data => this.layout.isolated_ids.includes(data.id) && !data.root);
        }
        return items;
    }

    getFilterCollectionWithAssociatedModules() {
        let collectionArr = []
        let roadmapData = store.getters['roadmap/programmatic']
        const modules = this.getModules(roadmapData)
        const module = this.getModule(modules, this.selectedModuleId)
        if (!module) {
            return []
        }

        const moduleLastLevels = module.last_levels

        this.getData(this.filterCollections).forEach(collection => {
            const associations = this.getAssociationsByFilterCollectionId(collection.id)
            const associatedIds = associations.map(item => item.associated_id)
            let associatedModules = moduleLastLevels.filter(item => {
                if (associatedIds.includes(item.id)) {
                    return item
                }
            })
            this.setAssociatedItemsMaxLength(associatedModules)
            collectionArr.push({
                filterCollection: collection,
                associatedModules: associatedModules
            })
        })

        return collectionArr
    }

    parameterTitles(widgetParameters, modules, selectedModuleId) {
        if (!modules || !selectedModuleId) {
            return []
        }
        const module = this.getModule(modules, selectedModuleId)
        return getParameterTitles(widgetParameters, module.properties)
    }

    /*
        *Returns Array of sample value
        *@param {array} columnProperties The properties array of last label
        *@param {array} widgetParameters
    */
    getSampleValues(columnProperties, widgetParameters) {
        let values = []
        widgetParameters.forEach(item => {
            let column = columnProperties.find(column => column.property_collection_id == item.id)
            if (column) {
                values.push(column.sample_value)
            }
        })
        return values
    }

    getParameterBg(value, definitionStyles, serialIndex) {
        if (value > 0) {
            value = value - .000001;
        }
        let valueIndex = Math.floor(value / 2);
        if (valueIndex > 4) {
            valueIndex = 4;
        }
        if (this.minRange[serialIndex] < value) {
            return definitionStyles[valueIndex].bg;
        }
        return "#C9C9C9";
    }

    getAddModuleDisplayPermission(associatedItemLength, renderLimit, showDropdown) {
        if (associatedItemLength == 0) {
            return true
        }
        if (renderLimit + 1 == this.maxItemLength && showDropdown) {
            return true
        }
        if (associatedItemLength < renderLimit + 1 && showDropdown) {
            return true
        }
    }

    getParameterBgByValue(value, definitionStyles, index=null) {
        return getParameterBg(value, definitionStyles, index)
    }

}
