<template>
      <select
        class="panzoom-exclude"
        :class="[classes]"
        v-model="localValue"
        @change="onChange"
        >
        <option v-if="label" :value="undefined" disabled selected>{{ label }}</option>
        <template  v-for="(item, index) in items">
          <slot v-bind="{item, index}">
            <option
            :class="[item.id == selected ? 'hidden' : '']"
            :key="index"
            :value="item[valueKey]"
            v-text="item[nameKey]"
          >
          </option>
          </slot>
        </template>
        </select>
</template>

<script>
export default {
    name: 'InputSelect',
    props: {
      items: {
        type: [Array, Object],
        required: true
      },
      selected: {
        type: [Number, String],
      },
      nameKey: {
        type: String,
        default: 'title'
      },
      valueKey: {
        type: String,
        default: 'id'
      },
      value: {
        required: false
      },
      label: {
        type: String,
        required: false
      },
      outerLabel: Boolean,
      classes: {
        type: String,
        default: 'fw-500 fs-10 text-secondary-one pr-select-dropdown param-dropdown bg-transparent w-full i-border-1 border-secondary-five rounded-sm py-1'
      },
      labelClasses: {
        type: String,
        default: 'text-secondary-two fs-10 fw-500'
      }
    },
    data() {
      return {
        localValue: undefined
      }
    },
    methods: {
      onChange() {
        this.$emit('change', this.localValue)
        setTimeout(() => {
          this.localValue = undefined
        }, 1000)
      }
    }
}
</script>

<style scoped>
  
  .styled-select select {
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
  }
  select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("/images/icons/chevron_down.svg");
  background-repeat: no-repeat;
  background-size: 15px;
  background-position-x: 99%;
  background-position-y: 0px;
  padding-left: 8px;
}
</style>
