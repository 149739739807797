import store from '@/store';
import { ref } from 'vue';
import { get } from 'lodash'
import Collection from '@/store/services/traits/collection';
import { peopleFullName } from '@/store/modules/roadmap/services';
import { getSelectedParamTitle } from "@/store/modules/roadmap/services.js";
import { getParameterBg } from '@/store/services/traits/parameter.js';
export default function usePeople() {
    const collection = new Collection;
    const peoples = ref([]);
    
    const getBackground             = (level, module) =>  collection.getBackground(level, module);
    const fullName                  = people => peopleFullName(people);
    const getRole                   = people => people?.label?.title || ''
    const getParameterBackground    = (value, definitionStyles) => getParameterBg(value, definitionStyles, null);
    const getUserAvatar             = people => get(people, 'user.image.file_path', '/images/profile.png');

    const getPeoplesByWidgetId = async (widgetId) => {
        let { data } = await store.dispatch("people/getPeoples", widgetId).then(data => data.data);
        peoples.value = data.map(_setVisibility);
    }
    const _setVisibility = item => {
        item.visibility = true;
        return item;
    }
    const getPeopleWiseParameter = (people, widget, module) => {
        let currentPeople = widget.parameters.find(item => item.people_id == people.id);
        if (!currentPeople) {
            return [];
        }
        return currentPeople.parameters.map(item => _setPropertyTitle(item, module));
    }
    const _setPropertyTitle = (item, module) => {
        item.propertyTitle = getSelectedParamTitle(item, module.properties);
        return item;
    }
    return {
        peoples,
        fullName,
        getRole,
        getBackground,
        getUserAvatar,
        getParameterBackground,
        getPeoplesByWidgetId,
        getPeopleWiseParameter,
    }
}