<template>
    <h2
        v-html="getText()"
        :title="title + ' ' + subTitle"
        class="w-40 inner rotate absolute font-poppins truncate text-center"
    ></h2>
</template>

<script>
export default {
    name: 'VerticalText',
    props: {
      title: {
        required: false
      },
      subTitle: {
        required: false
      },
      showTitle: {
        default: true
      }
    },
    methods: {
        getText() {
            if(!this.title) return this.addSpan('Default', true)
            let modifiedTitle = this.showTitle ? this.addSpan(this.title, true) : '';
            if(!this.subTitle) return modifiedTitle
            let modifiedSubTitle = this.addSpan(this.subTitle, false)
            return `${modifiedTitle}<span class="text-primary-two">_</span>${modifiedSubTitle}`
        },

        addSpan(text, isTitle) {
            let textArr = text.split(' ')
            if(textArr.length === 1) return `<span class="${isTitle ? 'titleStyle' : 'subtitleStyle'}">${textArr[0]}</span>`

            let joinedText = textArr.join(`<span class="text-primary-two">_</span>`)
            return `<span class="${isTitle ? 'titleStyle' : 'subtitleStyle'}">${joinedText}</span>`
        }
    }
}
</script>

<style>
.rotate {
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(-90deg);
}
.titleStyle {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #8D8D8D;
}
.subtitleStyle {
    font-size: 12px;
    font-weight: 500;
    color: black;
}
</style>
